import { useEffect, useRef, useState } from "react";
import search from '../../../assets/img/search.svg';
//import search from './..  ../../../assets/search.svg';

export interface IIput {
    key?:string,
    typeIn?:string,
    title?:string,
    required?:boolean,
    id?:string,
    name?:string,
    placeholder?:string
    checkTitle?:string
    search?:boolean,
    style?:any,
    register?:any
    errors?:any
    errorsmsn?:string,
    searchClick?:(data:any)=>void 
}

export const InputPart = (props:IIput, )=>{

    const inputLocal:any = useRef();
    const [value,setvalue] = useState<any>("")

    const returnValue = ()=>{ 
        if(props.searchClick){ props.searchClick(inputLocal.current.value); }
    }

    return(
        <label className={props.style} >
            {props.title && ( props.typeIn !== 'hidden' ) && <span>{props.title} </span> } 
            {props.required && ( props.typeIn !== 'hidden' ) && <span className="requiere" >*</span> } 
            <input 
                type={props.typeIn || 'text' }  
                ref={inputLocal} 
                {...props.register && props.register(props.name,{required:props.required})} 
                placeholder={props.placeholder}
                />
            {props.search &&
                <button className="button-search" onClick={returnValue}  >
                    <img src={search} />
                </button>
            }
            {props.checkTitle && <span>{props.checkTitle}</span>}
            {props.name && props.name && props.errors && props.errors[props.name]  &&  
                <span className="errorMsn" >* {props.errorsmsn}</span>  
            } 
            <div className="separator" ></div>
        </label>
    )

}