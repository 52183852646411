import headicon from "../../assets/img/H&S.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const FootePart = ()=>{
    return(
        <footer className="row" >
            <div className="col-12 col-md-6" data-aos="flip-down"  data-aos-duration="1300" >
                <div className="line" >Pereira,Colombia</div>
                <div className="line" >+57 3146172247 - 3006111716</div>
                <div className="line" >info@hysintegrar.com</div>
                <div className="line" >Horario de atención Lunes a Viernes de 8 a 12 y 2 a 5 pm</div>
            </div>
            <div className="col-12 col-der col-md-6" data-aos="fade-left"  data-aos-duration="1200" >
                <img className="img-full"  src={headicon} />
            </div>
            
        </footer>
    )
} 