import axios from 'axios';
import {useForm} from "react-hook-form";
import contact from "../../assets/img/email_contact.jpg" 
import { InputPart } from "./form/InputPart";
import { TextareaPart } from "./form/TextareaPart";
import AOS from 'aos';
import 'aos/dist/aos.css';

type InputContact = {
    name:string,
    email:string,
    whats:string,
    detail:string
}

const errorsMsn = {
    name:"Debe ingresar un Nombre",
    email:"Debe ingresar contraseña",
    detail:"Debe agregar un comentario"
}

const sendData = (data:any)=>{
    console.log("data to send::: ", data);
    /*var path = urlServer;
    if(getUserType()==="test"){  path = urlServerTest; }

    const result = await axios.post(path+listPath["process"]+'API_SLV_ANU?api_key='+apiKey,data);
    const dataResult = result.data; 

    if(dataResult.ERR_COD !== undefined && dataResult.ERR_COD < 1 ){
        return  {state:false,mng:dataResult.ERR_DES};
    }else if(result.data===""){
        return  {state:false,mng:"Problemas en el servicio"};
    }else{
        return  {state:true,id:dataResult.ID_SLV}
    }*/
}


export const FormPart = ()=>{

    const { register,handleSubmit,watch,formState:{errors} } = useForm<InputContact>();

    return(
        <div className="row cnt-form " >
            <div className="line" ></div>
            <div className="cnt-form-center" >
                <div className="cnt-img" data-aos="fade-right" data-aos-duration="1200"  >
                    <img src={contact} alt="" />
                </div>
                <form onSubmit={handleSubmit(sendData)} data-aos="fade-left" data-aos-duration="1200"  >
                    <h5>CONTÁCTENOS</h5>
                    <InputPart 
                        title="Nombre"
                        placeholder="Nombre"
                        register={register}
                        name="name"
                        required={true}
                        errors={errors}
                        errorsmsn={errorsMsn["name"]}
                        
                    />
                    <InputPart 
                        title="Correo"
                        placeholder="Correo"
                        register={register}
                        name="email"
                        required={true}
                        errors={errors}
                        errorsmsn={errorsMsn["email"]}
                    /> 
                    <InputPart 
                    title="N° WhatsApp (opcional)"
                    placeholder="N° WhatsApp (Opcional)"
                    register={register}
                    name="whats"
                />
                    <TextareaPart 
                        title="Comentario"
                        placeholder="Comentario"
                        required={true}
                        register={register}
                        name={"detail"}
                        errors={errors}
                        errorsmsn={errorsMsn["detail"]}
                    />
                    <button>Enviar</button>
                </form> 
            </div>
        </div>
    )

} 