import {useEffect, useState } from 'react';
import {Headpart} from './parts/HeadPart';
import woman from '../assets/img/woman.jpg';
//import woman from '../assets/img/woman1.jpg';
import { SoftwarePart } from './parts/SoftwarePart';
import { SolutionsPart } from './parts/SolutionsPart';
//import { CarouselComponent } from './carousel/CarouselComponent';
import { FormPart } from './parts/FormPart';
import { FootePart } from './parts/FooterPart';
import { ViewContentModal } from './modal/ViewContentModal';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const IndexComponent = ()=>{

    const [headShort,setHeadShort] = useState<boolean>(false);
    const [showModalState,setShowModalState] = useState<boolean>(false);
    /*modal elements*/ 
    //const [titleModal,setTitleModal ] = useState<string>("test");
    const [itemModal,setItemModal ] = useState<any>({});

    const handleScroll = (event:any)=>{

        //console.log("::::  posicion scroll",window.scrollY)

        const scrollPosition = window.scrollY;

        if(scrollPosition>50){ setHeadShort(true);
        }else{ setHeadShort(false); }
    }

    const showModalEvent = ()=>setShowModalState(!showModalState);

    const escEvent = (event:any)=>
    {
        if(event.key==="Escape"){ setShowModalState(false); }
    }

    useEffect(()=>{

        AOS.init();

        window.addEventListener('scroll',handleScroll);
        document.addEventListener('keydown',escEvent,false)

        return ()=>{
            window.removeEventListener('scroll',handleScroll);
            document.removeEventListener('keydown',escEvent);
        }
    
    },[])

    return(
        <div  className="container-fluid"  >
            <div className='area' >
                <ul className='circles' >
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <Headpart head={headShort} />
            <div className='head-separator'></div>
            <div className='row intro-info' >
                <div className='row cnt-info' >
                    <div className='col-12 col-md-6' data-aos="zoom-in-right" data-aos-duration="2500" > 
                        <div className='text-col-izq' >
                            <h3>Soluciones de Software</h3>
                            <p>
                            H&S Integradores de Tecnología es casa desarrolladora de software, creamos soluciones de software personalizadas y apropiadas a las necesidades de las empresas.
                            <br/><br/>
                            Conocemos las empresas y las ayudamos a lograr sus objetivos, proyectos, emprendimientos, integramos eficientemente sus procesos para que no se detengan y logren sus metas, ayudamos a construimos sueños con amabilidad, eficiencia, apoyados en plataformas y soluciones tecnológicas ágiles, amigables y a su alcance
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 cnt-col-der-first' >
                        <div className='color-1' ></div>
                        <div className='dots-mosaic' data-aos="zoom-in" data-aos-duration="1200"  ></div>
                        <div className='img-character' data-aos="fade-right"  data-aos-duration="2500" >
                            <img src={woman} alt="Hys" />
                        </div>
                    </div>
                </div>
                <div className='color-2' ></div>
            </div>
            <h3 className='general-title'  data-aos="fade-down"  data-aos-duration="1500" >Nuestro Software</h3>
            <SoftwarePart open={showModalEvent} setElement={setItemModal} />
            <h3 className='general-title' data-aos="fade-down"  data-aos-duration="1500" >Software</h3>
            <SolutionsPart open={showModalEvent} setElement={setItemModal} />
            {/*<CarouselComponent />*/}
            <FormPart />
            <FootePart />
            {showModalState && 
                <ViewContentModal 
                    show={showModalState} 
                    content={itemModal}
                    close={showModalEvent} /> 
            }
        </div>
    )
}