import parse from 'html-react-parser';
import { listSoftware } from '../list';

interface dataSoft {
    open:()=>void,
    setElement:(title:string)=>void
}



export const SoftwarePart = (props:dataSoft)=>{

    const openData = (item:any)=>{

        props.setElement(item)
        props.open()
    } 

    return(
        <div className="row cnt-card-soft" >
            <div className='card_soft-back' ></div>
            <div className='dots-mosaic' data-aos="zoom-in" data-aos-duration="1000"  ></div>
            <div className='parent-card-soft' >
                {
                    listSoftware.map((item)=>{
                        return <div className='card-soft' data-aos="fade-up" data-aos-duration="1200" onClick={()=>openData(item)} key={"soft_"+item.id}  >
                            <img src={item.banner} alt="Hys"  />
                            <div className='cnt-data' >
                                <h3>{parse(item.title)}</h3>
                                <p>{item.short}</p>
                                <div className='btn-see-soft' >Ver más</div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )

} 