export const listSoftware = [
    {
        "id":"hospitalario",
        "type":"soft",
        "banner":require("../../assets/img/hospital.jpg"),
        "logo":require("../../assets/img/logo-sion-HOSPITALARIO.png"),
        "short":"Plataforma modular que integra los procesos administrativos, financieros, asistenciales, estadísticos y normativos.",
        "title":"SION Hospitalario",
        "descriptions":
            [   {"id":1,"type":"p","content":"SION Integra todos los procesos Hospitalarios, Administrativos y Financierosde la Institución"},
                {"id":2,"type":"t","content":"SION CLÍNICO"},
                {"id":3,"type":"lt","content":[
                    "Historia Clínica Dinámica",
                    "Turnero",
                    "Agendamiento Médico",
                    "Órdenes, Procedimientos y Medicamentos",
                    "Órdenes Externas",
                    "Consulta Ambulatoria",
                    "Vacunación y Programas PyP",
                    "Actividades Extramurales",
                    "Odontología",
                    "Laboratorio Clínico",
                    "Ayudas Diagnósticas",
                    "Farmacia",
                    "Urgencias (Con Triage)",
                    "Hospitalización",
                    "Todos los informes y Anexos de Ley (Remisiones, SOAT,etc.)"
                

                    ]
                },
                {"id":4,"type":"t","content":"SION ADMINISTRATIVO Y CONTRATACIÓN"},
                {"id":5,"type":"lt","content":[
                    "Presupuesto Oficial y Contratación",
                    "Contabilidad",
                    "Cartera (Con Gestión de Glosas Individuales)",
                    "Tesorería",
                    "Bancos",
                    "Compras",
                    "Inventarios",
                    "Activos Fijos",
                    "Nómina",
                    "Ventanilla Única",
                    "Archivo documental",
                    "Cuadro de Mando integral",
                    "Gestión de Procesos y PQR (WorkFlow, Gestión de Correos) Programador Tareas Facturación Electrónica"
                    ]
                },
               

                //{"id":3,"type":"i","content":require("../../assets/img/Image_test.png")},
//                {"id":4,"type":"l","content":"https:\\www.hysintegrar.com\\template"},
            ],
        "guia":[{"t":"title"},{"p":"paragraf"},{"i":"image"},{"l":"link"},{"lt":"list"}]
    },
    {
        "id":"logistica",
        "type":"soft",
        "banner":require("../../assets/img/logistic1.jpg"),
        "logo":require("../../assets/img/SION_LOG720.png"),
        "short":"Toda la operación de la empresa desde el mismo lugar, Organice, Consulte, Gestione y mucho mas.",
        "title":"SION Logistica",
        "descriptions":
            [
                //{"id":"1","type":"p","content":"Organice el trabajo de su equipo en un espacio compartido, automatizando el trabajo rutinario."},
                /*{"id":"2","type":"lt","content":[
                    "Organice el trabajo de su equipo en un espacio compartido, automatizando el trabajo rutinario",
                    "Consulte el estado de los contratos y la información histórica de sus clientes.",
                    "Gestione el agendamiento de la operación logística por equipos de trabajo o a discreción.",
                    "Controle la operación en campo de su equipo de trabajo logístico, en tiempo real.",
                    "Cree y estructure encuestas a la medida, desde instrumentos de calidad, hasta encuestas para valoración de empleados.",
                    "App Operation Offline",
                    "App Supervisor Offline"
                ]
                },*/
                {"id":"3","type":"p","content":"Descubre por qué SION Logística es la elección definitiva para impulsar tu negocio:"},
                {"id":"4","type":"lt","content":[
                    "<span>Gestión Comercial:</span>Control total de tus clientes desde la propuesta hasta la acción concreta.",
                    "<span>Gestión de Personal:</span>Unifica información esencial de empleados, asignación de tareas y más.",
                    "<span>Gestión de Facturación:</span>Olvídate de problemas en la facturación, conectada directamente a operaciones.",
                    "<span>Gestión de Operación:</span>Órdenes de trabajo alineadas con contratos, control en tiempo real y agenda eficiente.",
                    "<span>Integración con Otras Plataformas:</span>Integración fluida con ERP y aplicaciones externas.",
                    "<span>Gestión de Contratos:</span>App Genera, oficializa y realiza seguimiento preciso de contratos.",
                    "<span>Control en Tiempo Real - Aplicaciones para Supervisores y Operarios:</span>Control avanzado con seguimiento en tiempo real y automatización.",
                    "<span>Gestión de Activos:</span>Control detallado de equipos, registro de operación y mantenimiento.",
                    "<span>Gestión de Compras:</span>Centralización de insumos y dotaciones para una gestión eficiente.",
                    "<span>Gestión Documental:</span>Organización y enlace en tu nube de almacenamiento.",
                    "<span>Modo Offline:</span> Aplicativo con funcionamiento fuera de linea con almacenamiento interno. "
                ]
                },
                {"id":"5","type":"i","content":require("../../assets/img/phone_supervisor.jpg") },
                {"id":"6","type":"i","content":require("../../assets/img/phone_operation.jpg") },
                {"id":"7","type":"l","content":"https://www.totalservicecontrol.com/"},
            ]
    },
    {
        "id":"kdocs",
        "type":"soft",
        "banner":require("../../assets/img/documents.jpg"),
        "logo":require("../../assets/img/KDOCS720.png"),
        "short":"Una sencilla y poderosa aplicación que  simplifica las tareas operativas de generación y envío de soportes documentales.",
        "title":"KDOC'S",
        "descriptions":
            [
                {"id":1,"type":"p","content":"Es una sencilla y poderosa aplicación que  simplifica las tareas operativas de generación y envío de soportes documentales de las atenciones clínicas hacia las Administradoras de salud, garantizando el cargue a sus plataformas, la custodia durante el tiempo establecido y finalmente evitando las glosas por errores en la gestión de dichos documentos digitales."},
                {"id":2,"type":"l","content" :"https://kdocs.hysintegrar.com/"}
            ]
    },
    {
        "id":"erp",
        "type":"soft",
        "banner":require("../../assets/img/erp1.jpg"),
        "logo":require("../../assets/img/sion_erp.png"),
        "short":"Es un sistema modular para el sector Gubernamental, Multiplataforma, Cumple la normatividad tributaria colombiana.",
        "title":"SION ERP",
        "descriptions":
            [   
                {id:"1",type:"p",content:"Es un sistema modular para el sector Gubernamental"},
                {id:"2","type":"lt","content":[

                    "Multiplataforma",
                    "Cumple la normatividad tributaria colombiana",
                    "Servicio (CLOUD)",
                    "Trazabilidad desde los registros contables hasta los orígenes de la información",
                    "Ciclo integrado de compras y ventas",
                    "Integración con los módulos administrativo y financiero ",
                    "Búsqueda por relaciones entre las entidades",
                    "Generación automática de órdenes de compra a través de los pedidos de venta",
                    "Inventario permanente",
                    "Modulo financiero integrado cartera tesorería, manejo de cuotas, bancos con la información contable",
                    "Generación automática de registros contables a partir de bases tributarias y mucho más",
                    ]
                },
                {id:"3",type:"p",content:"Integra los siguientes servicios:"},
                {id:"4","type":"lt","content":[
                    "Contabilidad",
                    "Nómina", 
                    "Presupuesto Estatal",
                    "Facturación Electrónica",
                    "Tesorería",
                    "Bancos",
                    "Compras",
                    "Inventarios",
                    "Activos Fijos",
                ]
                }
            ]
    },
    {
        "id":"gestio",
        "type":"soft",
        "banner":require("../../assets/img/gestion.jpg"),
        "logo":require("../../assets/img/logo_control.png"),
        "short":"Gestione eficientemente sus proyectos.",
        "title":"SION GESTION PROYECTOS",
        "descriptions":
            [  
                {id:"1","type":"lt","content":[
                    "Alcance del proyecto",
                    "Gestión del tiempo",
                    "Gestión del coste ",
                    "Gestión de la calidad", 
                    "Gestión de recursos humanos",
                    "Gestión de las comunicaciones",
                    "Gestión de riesgos",
                    "Gestión de adquisiciones y Gestión de Consultas"
                    ]
                },
                {id:"3",type:"p",content:"Personalice a la medida su proyecto y heredará igualmente todas las funcionalidades de la Gestión financiera y de la Gestión comercial"},
                
            ]
    },
    {
        "id":"sports",
        "type":"soft",
        "banner":require("../../assets/img/sport.jpg"),
        "logo":require("../../assets/img/logo_sion_sport720.png"),
        "short":"Software para la gestión y administración de Clubes Deportivos.",
        "title":"SION SPORTS",
        "descriptions":
            [  
                {
                    id:"1","type":"lt","content":[
                    "Disponible en nube desde cualquier dispositivo con Windows, Linux, Mac, Android o IOS, en cualquier momento y desde cualquier lugar del mundo. En la medida que el sistema evolucione todos los usuarios disfrutarán de los nuevos beneficios inmediatamente.",
                    "Registro de los deportistas ",
                    "Registro de datos antropométricos según las frecuencias de medición que tengan determinado los entrenadores: Peso, Talla e IMC, Datos de Cabeza, Cuello, Tórax, Manos, Piernas",
                    "Obtener indicadores de Peso óseo, Pliegues, % graso, Percentiles, etc.",
                    "Funciones administrativas tales como:",
                    "Impresión del formato de Ficha de inscripción",
                    "Registro de asistencias a entrenamientos y partidos",
                    "Registro de datos de partidos: Asistentes, goles, etc.",
                    "Plan de pagos por cada deportista",
                    "Registro e impresión de recaudos y actualización del % de ejecución.",
                    "Registro e impresión de gastos",
                    "Integración de Recaudos y Pagos con el sistema contable SION-Lite",
                    "El sistema de información SION-Lite, con el cual se integra SION Sports, está compuesto por los siguientes módulos:",
                    "Contabilidad, Cuentas por cobrar y pagar, Tesorería y bancos, Inventarios, Compras, Ventas",
                    ]
                }
            ]
    }    
]

export const listSolutions = [
    {
        "id":"sionlite",
        "type":"solution",
        "icon":require("../../assets/img/vel_windows.png"),
        "logo":"",
        "short":"Cliente en su versión Windows.",
        "title":"Velneo Cliente - Windows",
        "down":{"type":"l","content":"https://dl.velneo.com/velneo/35/velneo-win64-vclient-setup-35.1.3.exe"},
        "descriptions":
            [
            ],
        "guia":[{"p":"paragraf"},{"i":"image"},{"l":"link"}]
    },
    {
        "id":"sionips",
        "type":"solution",
        "icon":require("../../assets/img/vel_apple.png"),
        "logo":"",
        "short":"Cliente en su versión MacOs.",
        "title":"Velneo Cliente - Mac Os",
        "down":{"type":"l","content":"https://dl.velneo.com/velneo/35/velneo-macos64-vclient-setup-35.1.3.dmg"},
        "descriptions":
            [
            ],
    },
    {
        "id":"siongestion",
        "type":"solution",
        "icon":require("../../assets/img/vel_linux.png"),
        "logo":"",
        "short":"Cliente en su versión Linux.",
        "title":"Velneo Cliente - Linux",
        "down":{"type":"l","content":"https://dl.velneo.com/velneo/35/velneo-lin64-vclient-setup-35.1.3.tar.gz"},
        "descriptions":
            [
            ],
    }
] 