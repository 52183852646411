import parse from 'html-react-parser';
import { listSolutions } from '../list';

interface dataSolu {
    open:()=>void,
    setElement:(title:string)=>void
}


export const SolutionsPart = (props:dataSolu)=>{

   /* const openData = (item:any)=>{

        console.log(" open data ", item)
        props.setElement(item)
        props.open()
    } */

    return (

        <div className="row cnt-card-solutions" >
            <div className='parent-card-solutions' >
                {listSolutions.map((item)=>{

                    return(
                        <div  className='col-12 col-md-6 card-solutions' data-aos="fade-up"  data-aos-duration="1200"  key={"solu_"+item.id}  >
                            <div className='col-6 col-md-4 col-left' >
                                <img src={item.icon} alt='' />
                            </div>
                            <div className='col-12 col-md-8 col-right' >
                                <h3>{parse(item.title)}</h3>
                                <p>{item.short}</p>

                                {item.down !== undefined &&  item.down.type === "l" &&
                                    <a href={ item.down.content} >
                                        <div className='btn-see-solution' >Descargar</div>
                                    </a>
                                }
                                {item.descriptions.length > 0 &&
                                    <div className='btn-see-solution' >Ver más</div>
                                }
                            </div>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    )
}