interface ITextArea{
    title?:string,
    placeholder?:string,
    required?:boolean,
    name?:string,
    register?:any
    errors?:any
    errorsmsn?:string,
}

export const TextareaPart = (props:ITextArea)=>{
    return(
        <label htmlFor="">
            {props.title && <span>{props.title} </span> } {props.required && <span className="requiere" >*</span>} 
            <textarea name="" id="" {...props.register && props.register(props.name,{required:props.required})} placeholder={props.placeholder} ></textarea>
            {props.register &&  props.name && props.name && props.errors && props.errors[props.name]  &&  
                <span className="errorMsn" >* {props.errorsmsn}</span>  
            }
        </label>
    )
}