
import parse from 'html-react-parser';
import checkImg from "../../assets/img/check2-square_web.png";


interface dataModal {
    show:boolean,
    title?:string,
    content?:any,
    close:()=>void
}

export const ViewContentModal = (props:dataModal)=>{
    return( 
    <div className={props.show ? "modal modal-view": "modal"} >
        <div className="modal-dialog modal-product" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            {   props.content.logo?
                                <div className="cnt-img" >
                                    <img src={props.content.logo} alt="Hys" />

                                </div>
                                :
                                props.content.title ? 
                                    <h5 className="modal-title" id="exampleModalLabel">{props.content.title} </h5>
                                :
                                <div>
                                    default
                                </div>
                            }
                        </div>
                        <div className="modal-body" key={"body_"} >
                            <div className="row content-module" >
                                {   
                                    props.content.descriptions.map((item:any)=>{

                                        return(
                                                <div key={"modal_base_"+props.content.id+"_"+item.id} >
                                                {item.type !=="l" ?
                                                    <div className="col-12"  key={"modal_"+props.content.id+"_"+item.id} >
                                                        {item.type==="t" ?
                                                            <h4>{item.content}</h4>
                                                        :   item.type==="p" ?
                                                            <p  >
                                                                {parse(item.content)}
                                                            </p>
                                                        :item.type==="lt" ?   
                                                            <div key={"modal_lt_"+props.content.id+"_"+item.id} >
                                                                <ul>
                                                                    {item.content.length >0 &&
                                                                        item.content.map((lItem:any,index:any)=>{
                                                                            return <li key={"ls_"+index} >
                                                                                {parse(lItem)}
                                                                                <img src={checkImg} className="check" alt='' />
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        : item.type==="i" &&
                                                            <div className="cnt-img" key={"modal_i_"+props.content.id+"_"+item.id} >
                                                                <img src={item.content} alt='' />
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="col-12 cnt-link" key={"modal_link_"+props.content.id+"_"+item.id} >
                                                        <a href={item.content} target="_blank" rel="noreferrer" >Ver más</a>
                                                    </div>
                                                }
                                                </div>
                                                
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                        <div className="modal-footer" >
                            <div className="row" >
                                <div className="col-12 col-md-3 btn-bottom" >
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>props.close()} >Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
    )
}